//indexDB sever
function openDb(dbName: string, storeNames: any) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event: any) => {
      const db = event.target.result;
      storeNames.forEach(storeName => {
        if (!db.objectStoreNames.contains(storeName)) {
          const store = db.createObjectStore(storeName, {
            keyPath: 'id',
            autoIncrement: true,
          });
          if (storeName === 'files') {
            store.createIndex('name', 'name', { unique: false });
            store.createIndex('timestamp', 'timestamp', { unique: false });
            store.createIndex('size', 'size', { unique: false });
            store.createIndex('data', 'data', { unique: false });
          } else if (storeName === 'selectedPicInfo') {
            store.createIndex('image', 'image', { unique: false });
            store.createIndex('centerX', 'centerX', { unique: false });
            store.createIndex('centerY', 'centerY', { unique: false });
            store.createIndex('diameter', 'diameter', { unique: false });
          }
        }
      });
    };

    request.onsuccess = (event: any) => {
      resolve(event.target.result);
    };

    request.onerror = (event: any) => {
      reject(event.target.error);
    };
  });
}

async function addData(db: any, data: any, storeName: string, keyValue: any) {
  const dataExists = await checkDataExists(db, storeName, keyValue);
  if (dataExists) {
    console.log('Data already exists');
    return;
  }
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], 'readwrite');
    const store = transaction.objectStore(storeName);
    const request = store.add(data);

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = event => {
      reject(event.target.error);
    };
  });
}

//删除数据
function deleteData(db: any, name: string, storeName: string) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], 'readwrite');
    const store = transaction.objectStore(storeName);
    //根据name索引删除
    const request = store.delete(name);

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = event => {
      reject(event.target.error);
    };
  });
}

//clearData
async function clearData(db: any, storeNames: Array<string>) {
  return new Promise((resolve, reject) => {
    storeNames.forEach(async storeName => {
      const transaction = db.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);
      const request = store.clear();

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = event => {
        reject(event.target.error);
      };
    });
  });
}

function getAllData(db: any, storeName: string) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.getAll();

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = event => {
      reject(event.target.error);
    };
  });
}

function checkDataExists(db, storeName, key) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], 'readonly');
    const store = transaction.objectStore(storeName);
    let index: any;
    if (storeName === 'files') {
      index = store.index('name');
    } else {
      index = store.index('image');
    }
    const request = index.get(key);

    request.onsuccess = () => {
      resolve(request.result !== undefined);
    };

    request.onerror = event => {
      reject(event.target.error);
    };
  });
}

function getData(db: any, id: string, storeName: string) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.getAll();

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = event => {
      reject(event.target.error);
    };
  });
}

export default {
  openDb,
  addData,
  clearData,
  deleteData,
  getAllData,
  getData,
};

import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    //search
    path: '/search',
    name: 'search',
    component: () =>
      import(/* webpackChunkName: "search" */ '../views/SearchView.vue'),
  },
  {
    //detail/77eb86bb4ea6494ca993e224a7f4c850?page=1
    path: '/detail/:id',
    name: 'detail',
    component: () =>
      import(/* webpackChunkName: "details" */ '../views/DetailView.vue'),
    props: route => ({ id: route.params.id }),
  },
  // login
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
  },
  //修改密码
  {
    path: '/updatePassword',
    name: 'updatePassword',
    component: () =>
      import(
        /* webpackChunkName: "updatePassword" */ '../views/UpdatePasswordView.vue'
      ),
  },
  //忘记密码
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () =>
      import(
        /* webpackChunkName: "forgetPassword" */ '../views/ForgetPasswordView.vue'
      ),
  },
  //收藏夹
  {
    path: '/favorite',
    name: 'favorite',
    component: () =>
      import(/* webpackChunkName: "favorite" */ '../views/FavoriteView.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;

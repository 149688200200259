<template>
  <div class="main">
    <div id="3d-graph"></div>
    <div class="main-container-index">
      <div class="container">
        <div class="login">
          <div class="switch-lang">
            <span
              @click="changeLang('zh')"
              :class="lang === 'zh' ? 'active' : ''"
              >中文</span
            >
            <span>
              <van-divider
                vertical
                :style="{ borderColor: '#cda75e', margin: '0 8px' }"
              />
            </span>
            <span
              @click="changeLang('en')"
              :class="lang === 'en' ? 'active' : ''"
              style="padding-left: 3px"
              >EN</span
            >
          </div>
          <div class="login-box" v-if="user?.nickname">
            <span>
              <!-- 头像 -->
              <img class="avatar" :src="user?.avatar" alt="" />
            </span>
            <span v-if="user?.nickname" type="pr"> {{ user?.nickname }} </span>
            <div class="dropdwon" v-if="user?.nickname">
              <ul>
                <li>
                  <router-link to="/favorite">{{
                    $t('myFavorites')
                  }}</router-link>
                </li>
                <li @click="updatePassword">{{ $t('changePassword') }}</li>
                <li @click="logout">{{ $t('logout') }}</li>
              </ul>
            </div>
          </div>
          <div class="login-box login-step" v-if="!user?.nickname">
            <span> &nbsp;&nbsp;{{ $t('login') }}&nbsp;&nbsp; </span>
            <div class="login-info" :class="isEn ? 'en-modal' : ''">
              <div class="t">{{ $t('loginBenefits') }}</div>
              <ul class="info">
                <li>{{ $t('searchCases') }}</li>
                <li>{{ $t('joinCommunity') }}</li>
                <li>{{ $t('participateInDiscussion') }}</li>
                <li>{{ $t('createCaseCluster') }}</li>
              </ul>
              <div class="block-btn">
                <van-button type="primary" size="small" @click="login" block>
                  {{ $t('loginNow') }}
                </van-button>
              </div>
              <div class="register">
                {{ $t('firstTimeUse')
                }}<a href="###">{{ $t('clickToRegister') }}</a>
                {{ $t('or') }}
                <router-link to="/forgetPassword">
                  {{ $t('forgotPassword') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="header h1">
          <img src="../assets/logo.png" alt="" srcset="" />
        </div>
        <!-- <div class="header h2">
          <h1>DICOM <span>Hub</span></h1>
        </div> -->
        <!-- <h3 class="flag">超大规模医学影像病灶生态库</h3> -->
        <div class="search-box" :class="[{ focus: searchFocus }]">
          <van-search
            v-model="search.key"
            :placeholder="searchPlaceholder"
            show-action
            :clearable="false"
            @search="doSearch"
            @focus="searchFocus = true"
            @blur="searchFocus = false"
          >
            <template #action>
              <!-- <div @click="onClickButton">搜索</div> -->
              <div class="pic" @click="readFile()">
                <img src="../assets/upload_pic.svg" alt="search" />
              </div>
            </template>
          </van-search>
          <div class="btn">
            <button>{{ $t('doSearch') }}</button>
          </div>
        </div>
        <div class="hot-search">
          <span> <van-icon name="fire-o" color="#da3231" /></span>
          <span>
            <router-link to="/search?key=肺结节">肺结节</router-link>
          </span>
          <span>
            <router-link to="/search?key=肋骨骨折">肋骨骨折</router-link>
          </span>
          <span>
            <router-link to="/search?key=肺炎">肺炎</router-link>
          </span>
          <span>
            <router-link to="/search?key=肺结核">肺结核</router-link>
          </span>
        </div>
        <!-- <div class="upload-area" v-if="showUpload">
          <div class="upload-box">
            <UploadFiles
              v-model:showUpload="showUpload"
              v-model:handleFileList="handleFileList"
              v-model:directoryHandle="directoryHandle"
              v-model:showDicomModal="showDicomModal"
            />
          </div>
        </div>
        <div class="dicom-modal" v-if="showDicomModal">
          <div class="title">
            <div class="t">
              <van-icon name="search" />
              <span>查看DICOM以及标记病灶</span>
            </div>
            <div class="action">
              <van-icon name="cross" @click="showDicomModal = false" />
            </div>
          </div>
          <div id="viewer-content">
            <DicomViewer
              v-model:selectedPicResult="selectedPicResult"
              v-model:showDicomModal="showDicomModal"
            />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
declare const window: any;
import { defineComponent } from 'vue';
import ForceGraph3D from '3d-force-graph';
import lodash from 'lodash';
import { showToast } from 'vant';
import userService from '@/services/userService';
import { showDialog } from 'vant';
import { ref } from 'vue';
import indexDBSever from '@/services/indexDB';
import UploadFiles from '@/components/UploadFiles.vue';
import DicomViewer from '@/components/DicomViewer.vue';
// import * as THREE from 'three';
// import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'HomeView',
  components: {
    // UploadFiles,
    // DicomViewer,
  },
  data() {
    return {
      maxNodes: 15,
      distance: 1400,
      timer: 12000,
      search: {
        key: '',
      },
      searchPlaceholder: this.$t('searchPlaceholder'),
      searchFocus: false,
      directoryHandle: null,
      showUpload: false,
      handleFileList: [],
      db: ref(null),
      user: null,
      storeNames: ['files', 'selectedPicInfo'],
      indexedDB: {
        name: 'DICOMHub',
        storeName: 'files',
      },
      selectedPicInfoDb: {
        name: 'DICOMHub',
        storeName: 'selectedPicInfo',
      },
      selectedPicInfo: {
        image: '',
        centerX: 0,
        centerY: 0,
        diameter: 0,
      },
      selectedPicResult: {
        selectedRoiInfo: [],
        selectedImage: '',
        selectedDicom: {},
      },
      showDicomModal: false,
      lang: localStorage.getItem('lang') || 'zh',
    };
  },
  mounted() {
    this.loadGraph();
    this.checkUser();
  },
  methods: {
    async loadGraph() {
      let mockJson = '/json/mock.json';
      const elem = document.getElementById('3d-graph');
      if (!elem) return;
      const Graph = ForceGraph3D()(elem);
      //设置背景色
      // const scene = Graph.scene();
      // scene.background = new THREE.Color('0x000000');
      //应用背景色
      Graph.backgroundColor('#050505');
      let gData = {
        nodes: [],
        links: [],
      };
      let res = await userService.getURLJson(mockJson);
      gData = this.filterGrphData(res.data, gData);
      Graph.graphData(gData)
        // .nodeLabel((node: any) => `${node.user}: ${node.description}`)
        .nodeColor((node: any) => {
          return '#A97636';
          // switch (node.user) {
          //   //user1-20 循环使用：#A97636/#DECA68/#FFF8DB/#8D5F26/#FFFFFF；
          //   case 'user1' || 'user6' || 'user11' || 'user16':
          //     return '#A97636';
          //   case 'user2' || 'user7' || 'user12' || 'user17':
          //     return '#DECA68';
          //   case 'user3' || 'user8' || 'user13' || 'user18':
          //     return '#FFF8DB';
          //   case 'user4' || 'user9' || 'user14' || 'user19':
          //     return '#8D5F26';
          //   case 'user5' || 'user10' || 'user15' || 'user20':
          //     return '#FFFFFF';
          // }
        })
        //定义粒子大小
        // .nodeThreeObject(() => {
        //   return new THREE.Mesh(
        //     new THREE.SphereGeometry(3),
        //     new THREE.MeshBasicMaterial({
        //       color: '#A97636',
        //       transparent: true,
        //       opacity: 0.15,
        //     })
        //   );
        // })
        .onNodeClick((node: any) => {
          window.confirm(`Node: ${node.user}'?`);
        })
        .nodeOpacity(0.12)
        .linkWidth(1)
        .linkOpacity(0.05)
        .linkDirectionalParticles(4)
        // .cameraPosition({ z: distance })
        .linkDirectionalParticleSpeed((d: any) => d.value * 0.001);
      Graph.d3Force('charge').strength(-120); // Adjust if necessary
      Graph.d3VelocityDecay(0.68); // Lower decay for longer motion
      let time = this.timer;
      let distance = this.distance;
      randomCameraPosition(Graph, distance);
      function randomCameraPosition(Graph: any, distance: number) {
        const nodes = Graph.graphData().nodes;
        const node = nodes[Math.floor(Math.random() * nodes.length)];
        const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

        const newPos =
          node.x || node.y || node.z
            ? {
                x: node.x * distRatio,
                y: node.y * distRatio,
                z: node.z * distRatio,
              }
            : { x: 0, y: 0, z: distance }; // special case if node is in (0,0,0)
        Graph.cameraPosition(
          newPos, // new position
          node, // lookAt ({ x, y, z })
          time // ms transition duration
        );
      }
      setInterval(() => {
        randomCameraPosition(Graph, distance);
      }, time);
    },
    filterGrphData(data: any, gData: any) {
      const users = [];
      const ids = [];
      const maxNodes = this.maxNodes;
      const blockId = [];
      gData.nodes = data.nodes;
      //用lodash找出数量节点超过10的用户
      const userCount = lodash.countBy(data.nodes, 'user');
      lodash.forIn(userCount, (value: any, key: any) => {
        if (value > 10) {
          users.push(key);
        }
      });
      //过滤出用户节点超过10的节点
      users.forEach((user, index) => {
        ids[index] = [];
        let nodes = data.nodes.filter(node => node.user === user);
        nodes.forEach(node => {
          if (ids[index].length < maxNodes) {
            ids[index].push(node.id);
          } else {
            blockId.push(node.id);
          }
        });
      });
      //过滤出用户节点超过10的节点的链接
      data.links.forEach((link: any) => {
        if (
          blockId.indexOf(link.source) === -1 &&
          blockId.indexOf(link.target) === -1
        ) {
          gData.links.push({
            source: link.source,
            target: link.target,
          });
        }
      });
      return gData;
    },
    doSearch() {
      //转向到搜索结果页面,并传递搜索关键字
      console.log(this.search.key);
      //执行搜索
      this.$router.push({
        name: 'search',
        query: {
          key: this.search.key,
        },
      });
    },
    async requestPermission() {
      try {
        const opts = { type: 'open-directory' };
        const handle = await window.showDirectoryPicker(opts);
        return handle;
      } catch (error) {
        showDialog({ message: '文件夹授权失败' });
        console.error('权限请求被拒绝', error);
        return null;
      }
    },
    async readFile() {
      // const handle = await this.requestPermission();
      // if (handle) {
      //   this.directoryHandle = handle;
      //   for await (const entry of handle.values()) {
      //     const file = await entry.getFile();
      //     //size 单位是MB, 保留两位小数
      //     const _size = (file.size / 1024 / 1024).toFixed(2) + 'MB';
      //     let _item = this.handleFileList.find(item => item.name === file.name);
      //     if (!_item) {
      //       this.handleFileList.push({ name: file.name, size: _size });
      //     }
      //   }
      //   this.showUpload = true;
      // }
      //
      //转向到搜索结果页面
      this.$router.push({
        name: 'search',
        query: {
          from: 'home',
        },
      });
    },
    //存储到indexDB, 上传时间戳，文件名，文件二进制流
    async saveFile() {
      const handle = this.directoryHandle;
      let db = await indexDBSever.openDb(this.indexedDB.name, this.storeNames);
      console.log(db);
      for await (const entry of handle.values()) {
        const file = await entry.getFile();
        const fileData = await file.arrayBuffer();
        const timestamp = new Date().getTime();
        const name = file.name;
        const size = file.size;
        //data 二级制流
        const data = new Uint8Array(fileData);
        await indexDBSever.addData(
          db,
          {
            timestamp,
            name,
            size,
            data,
          },
          this.indexedDB.storeName,
          name
        );
      }
      // this.mockSearch();
      // this.$router.push({
      //   name: 'search',
      //   query: {
      //     key: 'DICOM',
      //   },
      // });
    },
    async mockSearch() {
      let db = await indexDBSever.openDb(
        this.selectedPicInfoDb.name,
        this.selectedPicInfoDb.storeName
      );
      console.log(db);
      let mockData = {
        image:
          'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABESUNNAgAAAFVMBADOAAAAAgABAE9CAAACAAAAAAECAAIAVUkaADEuMi44NDAuMTAwMDguNS4xLjQuMS4xLjIAAgADAFVJQAAxLjMuNi4xLjQuMS45NTkwLjEwMC4xLjIuMTE0NTk1NDk3MTI4ODEwNjM0NDIxNDA3MjczMDQxNjA1NDY5NzQAAgAQAFVJEgAxLjIuODQwLjEwMDA4LjEuMgACABIAVUkcADEuMi4yNzYuMC43MjMwMDEwLjMuMC4zLjYuNAACABMAU0gQAE9GRklTX0RDTVRLXzM2NCAIAAUACAAAAEdCMTgwMzAgCAAIABYAAABPUklHSU5BTFxQUklNQVJZXEFYSUFMCAASAAgAAAAyMDIwMTIyMAgAEwAGAAAAMDEzNTQxCAAWABoAAAAxLjIuODQwLjEwMDA4LjUuMS40LjEuMS4yAAgAGABAAAAAMS4zLjYuMS40LjEuOTU5MC4xMDAuMS4yLjExNDU5NTQ5NzEyODgxMDYzNDQyMTQwNzI3MzA0MTYwNTQ2OTc0AAgAIAAIAAAAMjAyMDEyMjAIACEACAAAADIwMjAxMjIwCAAiAAgA',
        centerX: 334,
        centerY: 354,
        diameter: 20,
      };
      await indexDBSever.addData(
        db,
        mockData,
        this.selectedPicInfoDb.storeName,
        mockData.image
      );
    },
    login() {
      //转向到登录页面。增加登录成功后的返回地址
      this.$router.push({
        name: 'login',
        query: { redirect: this.$route.fullPath },
      });
    },
    //修改密码
    updatePassword() {
      this.$router.push({ name: 'updatePassword' });
    },
    checkUser() {
      const user = sessionStorage.getItem('user');
      if (user) {
        this.user = JSON.parse(user);
      }
    },
    logout() {
      showToast('退出登录成功！');
      sessionStorage.removeItem('user');
      this.user = null;
    },
    //修改语言
    changeLang(lang: string) {
      localStorage.setItem('lang', lang);
      location.reload();
    },
  },
  unmounted() {
    //清理Graph
    const elem = document.getElementById('3d-graph');
    if (elem) elem.innerHTML = '';
  },
  setup() {
    //判断是否为i18n英文
    const isEn = localStorage.getItem('lang') === 'en';
    return {
      isEn,
    };
  },
});
</script>
<style lang="less" scoped>
body {
  background: #000;
}
* {
  padding: 0;
  margin: 0;
}
.scene-nav-info {
  display: none !important; /* 使用 !important 确保覆盖库中的默认样式 */
}
.main {
  position: relative;
  width: 100%;
  height: 100%;
}
.main-container-index {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* 初始状态为透明 */
  animation: fadeIn 0.2s ease-in-out 1s forwards; /* 动画时长2秒，延迟2秒执行 */
}
.header {
  width: 100%;
  position: absolute;
  height: 50px;
  line-height: 50px;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  margin-top: -170px;
  text-align: center;
  /* 变色字体 */
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  /* 创建渐变背景 */
  background: linear-gradient(to right, #a17841, #f9f2d6);
  /* 设置背景只显示在文字上 */
  -webkit-background-clip: text;
  background-clip: text;
  /* 将文字颜色设置为透明以显示背景 */
  color: transparent;
}
.login {
  width: 200px;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #fff;
  font-size: 16px;
  z-index: 100;
  text-align: right;
  span {
    cursor: pointer;
  }
  .switch-lang {
    display: inline-block;
    padding-right: 10px;
    span.active {
      color: #d9ae58;
    }
  }
  .login-box {
    display: inline-block;
    position: relative;
    text-align: right;
    height: 30px;
    .dropdwon {
      display: none;
      position: absolute;
      top: 28px;
      right: 0;
      width: 100px;
      background-color: #1d1d1d;
      border-radius: 5px;
      overflow: hidden;
      ul {
        li {
          padding: 10px;
          cursor: pointer;
          font-size: 14px;
          text-align: center;
          &:hover {
            background-color: #a17841;
          }
        }
      }
    }
  }
  .login-box:hover {
    .dropdwon {
      display: block;
    }
  }
  .login-step {
    .login-info {
      display: none;
      position: absolute;
      padding: 10px;
      top: 30px;
      right: 0;
      width: 230px;
      background-color: #1d1d1d;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      .t {
        padding: 10px;
        font-size: 16px;
        text-align: left;
      }
      .info {
        margin: 0;
        padding: 0 10px 15px 10px;
        overflow: hidden;
        li {
          width: 50%;
          float: left;
          font-size: 14px;
          color: #fff;
          line-height: 1.8em;
        }
      }
      .block-btn {
        text-align: center;
        display: block;
      }
      .register {
        font-size: 14px;
        padding: 10px;
        text-align: center;
        a {
          color: #d9ae58;
        }
      }
    }
    .login-info.en-modal {
      .info {
        li {
          width: 100%;
          text-align: left;
          text-align: center;
        }
      }
    }
  }
  .login-step:hover {
    .login-info {
      display: block;
    }
  }
}
.header.h1 {
  /* clip-path 正方形 */
  // clip-path: inset(0 0 52% 0);
  //渐变字体颜色
  background: linear-gradient(to right, #a17841, #fbf4d5);
  -webkit-background-clip: text;
  background-clip: text;
  img {
    width: 250px;
  }
}
// .header.h2 {
//   /* clip-path 正方形 */
//   // clip-path: inset(54% 0 0 0);
// }
.flag {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 14px;
  margin-top: -80px;
  z-index: 100;
  color: #a3a3a3;
  font-size: 0.8rem;
}
.van-search {
  background: #33353650 !important;
  border-color: transparent !important;
}
.search-box {
  /* width: 100%; */
  /* min-width: 500px; */
  height: 50px;
  position: absolute;
  z-index: 120;
  left: 20px;
  right: 20px;
  top: 50%;
  margin-top: -30px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  .van-search {
    flex: 1;
    border-radius: 0 10px 10px 0;
  }
  .btn {
    flex: 0 0 60px;
    button {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      border-radius: 0 5px 5px 0;
      color: #d2b065;
      font-weight: bold;
    }
  }
}
.search-box.focus {
  input {
    color: #fff !important;
  }
  .van-field-placeholder {
    color: #333 !important;
  }
}
.search-box .box {
  height: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 5%;
}
.search-box .pic {
  width: 50px;
  height: 50px;
  /* 垂直居中 */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 10px 10px 0;
}
.search-box .pic img {
  width: 60%;
}
.hot-search {
  text-indent: -30px;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  margin-top: 40px;
  color: #a3a3a3;
  z-index: 100;
  span {
    text-indent: 0;
    font-size: 14px;
    margin: 0 5px;
    color: #d2b065;
    font-weight: bold;
  }
}
.van-tag--plain {
  background-color: transparent !important;
}
.upload-area {
  display: none;
}
//PC端
@media (min-width: 768px) {
  .search-box {
    left: 50%;
    top: 50%;
    margin-top: -30px;
    transform: translateX(-50%);
    width: 1000px;
  }
  .hot-search {
    left: 50%;
    transform: translateX(-50%);
  }
  .header {
    font-size: 24px;
    margin-top: -200px;
    img {
      width: 450px !important;
    }
  }
  .flag {
    font-size: 18px;
    margin-top: -110px;
    font-weight: normal;
  }
  .upload-area {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1000px;
    height: 300px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    border: #fff solid 1px;
    z-index: 200;
    margin-top: 175px;
    .upload-box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .selected-file-list {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .dbName {
          width: 100%;
          margin-bottom: 10px;
          display: flex;
          .input {
            flex: 1;
            .van-field {
              border-bottom: #a3a3a3 1px solid;
            }
          }
          .action {
            flex: 0 0 60px;
            .van-button {
              width: 100%;
              height: 100%;
              border-radius: 0;
            }
          }
        }
        ul {
          width: 100%;
          height: 100%;
          overflow: auto;
          li {
            width: 48%;
            margin: 0 1%;
            float: left;
            height: 30px;
            line-height: 30px;
            border-bottom: #eee 1px solid;
            text-align: left;
            .text {
              float: left;
              width: 80%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .remove {
              float: right;
              width: 20%;
              text-align: right;
              cursor: pointer;
            }
          }
        }
        .van-button-group {
          background-color: #f5f5f5;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .van-button {
            width: 100px;
            margin: 10px;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .dicom-modal {
    position: absolute;
    // width: 1200px;
    // height: 600px;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -70%);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    .title {
      flex: 0 0 20px;
      height: 20px;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      background: #322903;
      color: #fff;
      .t {
        display: flex;
        align-items: center;
        span {
          margin-left: 10px;
        }
      }
      .action {
        cursor: pointer;
      }
    }
    #viewer-content {
      flex: auto;
      > div {
        width: 100%;
        height: 100%;
        text-align: left;
      }
    }
  }
}
.avatar {
  position: relative;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>

import api from './api';
import host from './host';
import portal from './portal';

const userService = {
  getURLJson(url: string) {
    //读取本地json
    return host.get(url);
  },
  getResult(params: any) {
    //读取本地json
    const url = '/lesions';
    // post
    return api.post(url, params);
  },
  // 登录
  login(params: any) {
    const url = '/login';
    return portal.post(url, params);
  },
  //修改密码
  updatePassword(params: any) {
    const url = '/doctors/' + params.id + '/password';
    return portal.put(url, params);
  },
  //发送短信验证码
  sendCode(params: any) {
    const url = '/doctors/forget/sms';
    return portal.post(url, params);
  },
  //忘记密码通过短信验证码修改密码
  resetPassword(params: any) {
    const url = '/doctors/forget/reset';
    return portal.post(url, params);
  },
  getMyCollect(params: any) {
    const url = '/doctors/' + params.id + '/lesions/favor';
    return portal.get(url, { params: params });
  },
  //收藏病灶
  addCollect(params: any) {
    // /doctors/{doctorId}/lesions/{lesionId}/favor
    const url =
      '/doctors/' + params.doctorId + '/lesions/' + params.lesionId + '/favor';
    return portal.post(url, {
      groupId: params.groupId,
    });
  },
  //取消收藏病灶
  updateCollect(params: any) {
    // /doctors/{doctorId}/lesions/{lesionId}/favor
    const url =
      '/doctors/' + params.doctorId + '/lesions/' + params.lesionId + '/favor';
    return portal.put(url, {
      groupId: params.groupId,
    });
  },
  //获取收藏组
  getCollectGroup(params: any) {
    const url = '/doctors/' + params.doctorId + '/favorGroups';
    return portal.get(url, { params: params });
  },
  //修改收藏组
  updateCollectGroup(params: any) {
    const url = '/doctors/' + params.id + '/favorGroups';
    return portal.put(url, params);
  },
  //删除收藏组
  deleteCollectGroup(params: any) {
    const url = '/doctors/' + params.id + '/favorGroups/' + params.groupId;
    return portal.delete(url, params);
  },
  //新增分组
  addCollectGroup(params: any) {
    const url = '/doctors/' + params.doctorId + '/favorGroups';
    return portal.post(url, params);
  },
  //查询收藏分组下的病灶
  getCollectLesions(params: any) {
    const url = '/doctors/' + params.doctorId + '/lesions/favor';
    return portal.get(url, {
      params: params,
    });
  },
  //获取病灶的特征向量
  getLesionFeature(params: any) {
    const url = '/lesions/features';
    return api.post(url, params);
  },
  //获取病灶评论
  getComments(params: any) {
    const url = '/lesions/' + params.lesionId + '/comments';
    return portal.get(url, { params: params });
  },
  //新增病灶评论
  addComment(params: any) {
    const url = '/lesions/' + params.lesionId + '/comments';
    return portal.post(url, params);
  },
  //获取这病例的向量
  getLesionFeatures(params: any) {
    const url = '/lesions/feature/' + params.id;
    return api.get(url, params);
  },
};
export default userService;

export default {
  loginTitle: 'Log In to Your Account',
  usernamePlaceholder: 'Enter your username/phone number',
  passwordPlaceholder: 'Enter your password',
  loginButton: 'Sign In',
  registerAccount: 'Sign Up',
  forgotPassword: 'Forgot Password?',
  loginSuccess: 'Successfully Logged In',
  loginFormError: 'Please enter your username and password',
  pleaseInputUserName: 'Please enter your username',
  pleaseInputPassword: 'Please enter your password',
  // login
  myFavorites: 'My Favorites',
  changePassword: 'Profile',
  logout: 'Sign Out',
  login: 'Sign In',
  loginBenefits: 'After logging in, you can:',
  searchCases: 'Search through numerous cases',
  joinCommunity: 'Join our learning community',
  participateInDiscussion: 'Participate in case discussions',
  createCaseCluster: 'Create case clusters',
  loginNow: 'Log In Now',
  firstTimeUse: 'First time here? ',
  clickToRegister: 'Click here to sign up',
  or: 'or',
  searchPlaceholder: 'Please enter lesion description or disease...',
  doSearch: 'Search',
  hotSearch: 'Hot:',
  uoloadAndSearch: 'Upload your DICOM to start searching',
  PathologicalReport: 'Pathological',
  FollowUP: 'Follow-Up: ',
  selectDiseaseType: 'Disease Type',
  selectMalignancyLevel: 'Malignancy Level',
  selectLobe: 'Lung Lobe',
  minDiameter: 'Min Diameter(mm):',
  maxDiameter: 'Max Diameter(mm):',
  reset: 'Reset',
  focalArea: 'Lesion Area',
  diseaseType_null: 'Any',
  diseaseType_solid_nodule: 'Solid',
  diseaseType_partial_solid_nodule: 'Partially Solid',
  diseaseType_ground_glass_nodule: 'Ground Glass',
  diseaseType_calcified_nodule: 'Calcified',
  diseaseType_tumor: 'Tumor',
  diseaseType_unknown: 'Other',
  malignancyLevel_null: 'Any',
  malignancyLevel_benign: 'Benign',
  malignancyLevel_low: 'Low Risk',
  malignancyLevel_medium: 'Medium Risk',
  malignancyLevel_high: 'High Risk',
  malignancyLevel_unknown: 'Other',
  lungLobe_null: 'Any',
  lungLobe_right_top: 'Right Upper Lobe',
  lungLobe_right_middle: 'Right Middle Lobe',
  lungLobe_right_bottom: 'Right Lower Lobe',
  lungLobe_left_top: 'Left Upper Lobe',
  lungLobe_left_bottom: 'Left Lower Lobe',
  lungLobe_unknown: 'Other',
};

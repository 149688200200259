export default {
  loginTitle: '账号登录',
  usernamePlaceholder: '请输入用户名/手机号',
  passwordPlaceholder: '请输入密码',
  loginButton: '登录',
  registerAccount: '注册账号',
  forgotPassword: '忘记密码',
  loginSuccess: '登录成功',
  loginFormError: '请填写用户名和密码',
  pleaseInputUserName: '请输入用户名',
  pleaseInputPassword: '请输入密码',
  // login
  myFavorites: '我的收藏',
  changePassword: '修改密码',
  logout: '退出登录',
  login: '登录',
  loginBenefits: '登录后您可以：',
  searchCases: '搜索海量病例',
  joinCommunity: '加入学习社群',
  participateInDiscussion: '参与案例讨论',
  createCaseCluster: '创建病案集群',
  loginNow: '立即登录',
  firstTimeUse: '首次使用？',
  clickToRegister: '点此注册',
  or: '或',
  searchPlaceholder: '请输入病灶描述或者疾病...',
  doSearch: '搜索',
  hotSearch: '热门搜索：',
  uoloadAndSearch: '上传您的影像开始搜索',
  PathologicalReport: '病理报告',
  FollowUP: '随访次数：',
  // 过滤条件
  selectDiseaseType: '请选择病灶类型',
  selectMalignancyLevel: '请选择恶性等级',
  selectLobe: '请选择病灶肺叶',
  minDiameter: '长径最小值(mm)：',
  maxDiameter: '长径最大值(mm)：',
  reset: '重置',
  focalArea: '病灶部位',
  diseaseType_null: '不限',
  diseaseType_solid_nodule: '实性',
  diseaseType_partial_solid_nodule: '部分实性',
  diseaseType_ground_glass_nodule: '磨玻璃',
  diseaseType_calcified_nodule: '钙化',
  diseaseType_tumor: '肿块',
  diseaseType_unknown: '其他',
  malignancyLevel_null: '不限',
  malignancyLevel_benign: '无危',
  malignancyLevel_low: '低危',
  malignancyLevel_medium: '中危',
  malignancyLevel_high: '高危',
  malignancyLevel_unknown: '其他',
  lungLobe_null: '不限',
  lungLobe_right_top: '右上叶',
  lungLobe_right_middle: '右中叶',
  lungLobe_right_bottom: '右下叶',
  lungLobe_left_top: '左上叶',
  lungLobe_left_bottom: '左下叶',
  lungLobe_unknown: '其他',
};

import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import Vant, { Locale } from 'vant';
import 'vant/lib/index.css';
import './assets/style.less';
// import { registerMicroApps } from 'qiankun';
import { createI18n } from 'vue-i18n';
import en from './locales/en.js';
import zh from './locales/zh.js';

const app = createApp(App);

app.use(Vant);
// app.use(ConfigProvider);
// app.use(VueIframe);

const i18n = createI18n({
  legacy: false, // 禁用 legacy 模式
  locale: localStorage.getItem('lang') || 'zh', // 设置默认语言
  fallbackLocale: 'en',
  globalInjection: true, // 全局注入，以便在模板中使用 $t
  messages: {
    en,
    zh,
  },
});

//将语言模式存储在localStorage中
if (!localStorage.getItem('lang')) {
  localStorage.setItem('lang', i18n.global.locale.value);
}

app.use(router);
app.use(i18n);
app.mount('#mainApp');

// 注册子应用
// registerMicroApps([
//   {
//     name: 'viewerApp',
//     entry: '//localhost:5173',
//     container: '#viewer',
//     activeRule: '#/detail',
//     loader: loading => console.log(loading ? '加载中...' : '加载完成'),
//   },
// ]);
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then(registration => {
        // console.log('SW registered: ', registration);
      })
      .catch(registrationError => {
        // console.log('SW registration failed: ', registrationError);
      });
  });
}
